import { Auth } from "aws-amplify";
import axios from "axios";

export const auth = {
	namespaced: true,
	state: {
		user: null,
		token: null,
	},
	mutations: {
		setUser(state, payload) {
			state.user = payload;
		},
		setToken(state, payload) {
			state.token = payload;
		}
	},
	actions: {
		async signOut({ commit }) {
			commit("setUser", null);
			return await Auth.signOut();
		},
		async signIn({ commit }, { username, password }) {
			try {
				await Auth.signIn({ username, password });

				const userAuth = await Auth.currentAuthenticatedUser();
				const { attributes } = userAuth;

				const token = userAuth.signInUserSession.idToken.jwtToken;
				axios.defaults.headers.common["Authorization"] = token;
				commit("setUser", {
					username,
					attributes,
				});
				commit("setToken", token);

				return Promise.resolve("Success");
			} catch (err) {
				return Promise.reject(err);
			}
		},
		async confirmSignUp(_, { username, code }) {
			try {
				await Auth.confirmSignUp(username, code);
				return Promise.resolve("Success");
			} catch (err) {
				console.log(err);
				return Promise.reject(err);
			}
		},
		async signUp(_, { username, password, email }) {
			try {
				await Auth.signUp({
					username,
					password,
					attributes: { email },
				});
				return Promise.resolve("Success");
			} catch (err) {
				console.log(err);
				return Promise.reject(err);
			}
		},
		async signInFacebook() {
			try {
				console.log("attempting federated sign in...");
				await Auth.federatedSignIn({
					provider: "Facebook",
				});
				return Promise.resolve("Success");
			} catch (err) {
				console.log(err);
				return Promise.reject(err);
			}
		},
		async authAction({ commit }) {
			const userAuth = await Auth.currentAuthenticatedUser();
			const { username, attributes } = userAuth;

			const token = userAuth.signInUserSession.idToken.jwtToken;
			axios.defaults.headers.common["Authorization"] = token;

			commit("setUser", {
				username,
				attributes,
			});
			commit("setToken", token);
			return Promise.resolve("Success");
		},
	},
	getters: {
		user: (state) => state.user,
	},
};
