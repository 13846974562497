import { getUser, updateUserPinned } from "@/helpers/api/user";
import { updateUserTrelloKey } from "../helpers/api/user";
import { once, throttle, debounce } from "../helpers/functions";

const get = async ({ commit }) => {
	const res = await getUser();
	if (!res || !res.data)
		return {};

	commit("set", res.data.data);
};

const updatePinned = async ({ commit, dispatch }, pinned) => {
	commit("setLoading", true);
	await updateUserPinned(pinned);
	await dispatch("get");
	commit("setLoading", false);
};

const updateTrelloKey = async ({ commit, dispatch }, key) => {
	commit("setLoading", true);
	await updateUserTrelloKey(key);
	await dispatch("get");
	commit("setLoading", false);
};

export default {
	namespaced: true,
	actions: {
		get,
		getOnce: once(get),
		getThrottled: throttle(get, 600000),

		updatePinned,
		updatePinnedDebounce: debounce(updatePinned, 2000),

		updateTrelloKey: debounce(updateTrelloKey, 2000, true),
	},

	mutations: {
		set(state, payload) {
			if (!payload.pinned) payload.pinned = [];

			state.user = payload;
		},

		setLoading(state, loading) {
			state.loading = loading;
		}
	},

	state: {
		user: {},
		loading: false
	},

	getters: {
		data: (state) => ({
			current: state.user,
			loading: state.loading
		}),
	},
};
