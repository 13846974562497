<template>
	<div>
		<div v-if="loading">Loading...</div>
		<div v-if="!loading && !user">
			<form @submit.prevent="signIn">
				<div>
					<TextInput label="Email" v-model="email" />
				</div>
				<div>
					<TextInput
						label="Password"
						type="password"
						v-model="password"
					/>
				</div>
				<button>Sign in</button>
			</form>
			<button class="facebook" @click.prevent="signInFacebook">
				Sign in with Facebook
			</button>

			<div>Don't have an account?</div>
			<div>
				<router-link class="btn text" to="/sign-up"
					>Sign up for free!</router-link
				>
			</div>

			<div>{{ error.message }}</div>
		</div>
		<div v-if="!loading && user">
			<router-link to="/board">Go to board</router-link>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/inputs/TextInput";

export default {
	components: { TextInput },
	data: () => ({
		email: "",
		password: "",
		error: "",
		loading: false,
	}),
	created() {
		this.loading = false;
	},
	computed: {
		...mapGetters({
			user: "auth/user",
		}),
	},
	methods: {
		...mapActions({
			signInAction: "auth/signIn",
			signInFacebookAction: "auth/signInFacebook",
		}),
		async signIn() {
			this.error = "";
			this.loading = true;
			try {
				await this.signInAction({
					username: this.email,
					password: this.password,
				});
				this.$router.push("/board");
			} catch (err) {
				this.error = err;
				this.loading = false;
			}
		},
		async signInFacebook() {
			this.error = "";
			this.loading = true;
			try {
				await this.signInFacebookAction();
				this.$router.push("/board");
			} catch (err) {
				this.error = err;
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
