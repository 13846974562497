import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
dayjs.extend(durationPlugin);

export const toTime = millis => {
    const duration = dayjs.duration(millis);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let output = "";
    let str = "";
    let len = 0;

    if (hours > 0) {
        str = `00${hours}`;
        len = str.length;
        output = output + str.substring(len - 2, len) + ":";
    }

    str = `00${minutes}`;
    len = str.length;
    output = output + str.substring(len - 2, len) + ":";

    str = `00${seconds}`;
    len = str.length;
    output = output + str.substring(len - 2, len);

    return output;
}

export const useTimer = ({ fnElapsed, fnPlay, fnStop }) => {
    let interval = 0;
    let time = null;

    let obj = {
        playing: false,
        elapsed: 0
    }

    const resetElapsed = () => {
        time = Date.now();
        obj.elapsed = 0;
    }

    const play = () => {
        if (!time)
            time = Date.now();
            
        obj.elapsed = Date.now() - time;

        if (fnPlay)
            fnPlay(obj.elapsed);

        interval = setInterval(() => {
            const from = obj.elapsed;
            const to = Date.now() - time;
            obj.elapsed = to;
            if (fnElapsed)
                fnElapsed(to, from);
        }, 500);

        obj.playing = true;
    };

    const pause = () => {
        clearInterval(interval);
        time = null;
        obj.playing = false;
        
        if (fnElapsed)
            fnElapsed(obj.elapsed);

        return obj.elapsed;
    };

    const stop = () => {
        const elapsed = pause();
        if (fnStop)
            fnStop(elapsed);
        obj.elapsed = 0;
        return elapsed;
    };

    obj.play = play;
    obj.pause = pause;
    obj.stop = stop;
    obj.resetElapsed = resetElapsed;
    
    return obj;
}

export const MILLIS_TO_DAYS = 1 / (1000 * 60 * 60 * 24);