import { once, throttle } from "../helpers/functions";
import { list, create, update, getFromCode } from "../helpers/api/tasks";
import { ACTION } from "../data/dialog-keys";
import { v4 as uuid } from "uuid";
import { isnull } from "../helpers/objects";

const mapTaskToSchedule = (task) => ({
	name: task.name,
	defs: task.schedules,
	type: task.type,
	refId: task.refId,
});

// crud
const fnList = async ({ commit, dispatch }) => {
	const res = await list();
	commit("setList", res.data.data);
	dispatch(
		"registerSchedules",
		res.data.data.filter((x) => x.schedules).map(mapTaskToSchedule),
		{ root: true }
	);
	return res;
};
const fnCreate = async ({ commit }, data) => {
	const res = await create(data);
	commit("setTaskId", { refId: data.refId, id: res.data.data.id });
	return res;
};
const fnUpdate = async (_, { id, data }) => {
	const res = await update({ id, data });
	// await dispatch("list");
	return res;
};

const getTaskFromCode = async (_, code) => {
	const res = await getFromCode(code);
	return res;
};

const saveAction = async ({ state, dispatch }, refId) => {
	const task = state.actionRefIndex[refId];
	if (task.parentRefId) {
		return;
	}

	let id = task._id || task.id || null;
	if (!id) {
		console.log(`attempting to create tasks/${task.name} remotely`);
		await dispatch("create", task);
	} else {
		console.log(`attempting to update tasks/${task.name} remotely`);
		await dispatch("update", { id, data: task });
	}
};

const saveSequence = async ({ state, dispatch }, refId) => {
	const task = state.taskRefIndex[refId];
	let id = task._id || task.id || null;
	if (!id) {
		await dispatch("create", task);
	} else {
		await dispatch("update", { id, data: task });
	}
	console.log(task);
};

// action context
const saveActionContext = ({ state, commit, dispatch }, data) => {
	// commit to local state
	commit("saveActionContext", {
		refId: state.actionContext.refId,
		parentRefId: state.actionContext.parentRefId,
		...data,
	});

	dispatch(
		"updateScheduleRef",
		{
			refId: state.actionContext.refId,
			schedule: mapTaskToSchedule(state.actionContext),
		},
		{ root: true }
	);

	// add to remote save queue
	dispatch(
		"saveQueueAdd",
		{ type: "action", refId: state.actionContext.refId },
		{ root: true }
	);

	// call hooks
	state.actionContextHook.invokeSave(state.actionContext);
};
const setActionContext = ({ commit }, action) => {
	const hook = {
		onSaveHooks: [],
	};
	hook.onSave = (fn) => {
		hook.onSaveHooks.push(fn);
	};
	hook.invokeSave = (data) => {
		hook.onSaveHooks.forEach((fn) => {
			fn(data);
		});
	};

	commit("setActionContext", { action, hook });
	return hook;
};
const clearActionContext = ({ commit }) => {
	commit("setActionContext", { action: null, hook: null });
};

const openSingleActionDesigner = (
	{ commit, dispatch },
	{ isTask, id, inlineId }
) => {
	commit("openSingleActionDesigner", { isTask, id, inlineId });
	dispatch("pushDialog", ACTION, { root: true });
};
const closeSingleActionDesigner = ({ commit }) => {
	commit("closeSingleActionDesigner");
};

// sequence context
const saveSequenceContext = ({ state, commit, dispatch }, data) => {
	commit("saveSequenceContext", {
		refId: state.sequenceContext.refId,
		...data,
	});
	dispatch(
		"updateScheduleRef",
		{
			refId: state.sequenceContext.refId,
			schedule: mapTaskToSchedule(state.sequenceContext),
		},
		{ root: true }
	);
	dispatch(
		"saveQueueAdd",
		{ type: "sequence", refId: state.sequenceContext.refId },
		{ root: true }
	);
};
const setSequenceContextCreate = ({ commit }) => {
	commit("setSequenceContext", {
		refId: uuid(),
	});
};
const setSequenceContext = ({ commit }, task) => {
	// const task = getters.data.getFromRefId(refId);
	if (task.type != "sequence") return;

	commit("setSequenceContext", task);
};
const clearSequenceContext = ({ commit }) => {
	commit("setSequenceContext", null);
};

// dynamic source
const openDynamicSource = ({ commit }, source) => {
	commit("openDynamicSource", source);
};
const closeDynamicSource = ({ commit }) => {
	commit("closeDynamicSource");
};
const applyDynamicSource = ({ commit }, source) => {
	commit("applyDynamicSource", source);
};

const registerAction = ({ commit }, action) => {
	commit("registerAction", action);
};

// helpers
const mapActionExt = (action, parentRefId) => {
	action.parentRefId = isnull(action.parentRefId, parentRefId);
	action.units = isnull(action.units, []);
	action.positive = isnull(action.positive, true);
	action.effort = isnull(action.effort, 1);
	action.isDynamic = isnull(action.isDynamic, false);
	action.dynamicSources = isnull(action.dynamicSources, []);
	action.tags = isnull(action.tags, []);
	action.effects = isnull(action.effects, []);
	action.schedules = isnull(action.schedules, []);
	return action;
};

export default {
	namespaced: true,

	actions: {
		list: fnList,
		listOnce: once(fnList),
		listThrottled: throttle(fnList, 600000, true),

		getTaskFromCode,

		saveAction,
		saveSequence,

		create: fnCreate,
		update: fnUpdate,

		saveActionContext,
		setActionContext,
		clearActionContext,

		saveSequenceContext,
		setSequenceContext,
		setSequenceContextCreate,
		clearSequenceContext,

		openSingleActionDesigner,
		closeSingleActionDesigner,

		openDynamicSource,
		closeDynamicSource,
		applyDynamicSource,

		registerAction,
	},

	mutations: {
		addMenuSetActive: (state, payload) => {
			state.addMenu.open = payload;
		},
		setList: (state, payload) => {
			state.list = payload.map((task) => {
				let t = { ...task };
				return t;
			});

			state.list.sort((a, b) => (a.name < b.name ? -1 : 1));

			state.actionRefIndex = {};
			state.list.forEach((task) => {
				switch (task.type) {
					case "action":
						state.actionRefIndex[task.refId] = mapActionExt(task);
						break;
					case "sequence":
						Object.keys(task.inlineActions).forEach((k) => {
							const action = task.inlineActions[k];
							if (!action.refId) action.refId = uuid();

							state.actionRefIndex[action.refId] = mapActionExt(
								action,
								task.refId
							);
						});
						break;
				}
			});

			state.taskRefIndex = state.list.reduce((p, c) => {
				p[c.refId] = c;
				return p;
			}, {});
			state.taskDbIndex = state.list.reduce((p, c) => {
				p[c._id] = c;
				return p;
			}, {});
		},

		saveActionContext: (
			state,
			{
				refId,
				parentRefId,
				name,
				positive,
				effort,
				isDynamic,
				dynamicSources,
				units,
				tags,
				habits,
				schedules,
			}
		) => {
			if (!name) throw "Action requires name";

			let action = null;
			if (!refId || !state.actionRefIndex[refId]) {
				// create local
				refId = uuid();
				action = { id: null, refId, parentRefId, type: "action" };
				state.actionRefIndex[refId] = action;

				if (!parentRefId) {
					state.taskRefIndex[refId] = action;
					state.list.push(action);
				}
			} else {
				// get from local index
				action = state.actionRefIndex[refId];
			}

			if (!action) {
				// handle action missing
				return;
			}

			state.actionContext = action;

			if (name != null) action.name = name;
			if (positive != null) action.positive = positive;
			if (effort != null) action.effort = effort;
			if (isDynamic != null) action.isDynamic = isDynamic;
			if (dynamicSources != null) action.dynamicSources = dynamicSources;
			if (units != null) action.units = units;
			if (tags != null) action.tags = tags;
			if (habits != null) action.habits = habits;
			if (schedules != null) action.schedules = schedules;
		},
		setActionContext: (state, { action, hook }) => {
			state.actionContext = action;
			state.actionContextHook = hook;
		},

		setTaskId: (state, { refId, id }) => {
			state.taskRefIndex[refId].id = id;
		},

		saveSequenceContext: (
			state,
			{
				refId,
				name,
				actions,
				loops,
				inlineActions,
				tags,
				positive,
				schedules,
			}
		) => {
			if (!name) throw "Task requires name";

			let task = null;
			if (!refId || !state.taskRefIndex[refId]) {
				// create local
				refId = uuid();
				task = { id: null, refId, type: "sequence" };
				state.taskRefIndex[refId] = task;
				state.list.push(task);
			} else {
				// get from local index
				task = state.taskRefIndex[refId];
			}

			if (!task) {
				// handle task missing
				return;
			}

			if (name != null) task.name = name;
			if (actions != null) task.actions = actions;
			if (loops != null) task.loops = loops;
			if (inlineActions != null) task.inlineActions = inlineActions;
			if (tags != null) task.tags = tags;
			if (positive != null) task.positive = positive;
			if (schedules != null) task.schedules = schedules;

			state.sequenceContext = task;
		},
		setSequenceContext: (state, sequence) => {
			state.sequenceContext = sequence;
		},

		openSingleActionDesigner: (state, { isTask, id, inlineId }) => {
			state.singleActionDesigner = {
				isOpen: true,
				isTask,
				id,
				inlineId,
			};
		},
		closeSingleActionDesigner: (state) => {
			state.singleActionDesigner.isOpen = false;
		},

		openDynamicSource: (state, { source, context }) => {
			state.dynamicSource = source;
			state.dynamicSourceCtx = context;
		},
		closeDynamicSource: (state) => {
			state.dynamicSource = null;
			state.dynamicSourceCtx = null;
		},
		applyDynamicSource: (state, source) => {
			state.dynamicSourceCtx.$emit("input", source);
		},

		registerAction: (state, action) => {
			state.actionRefIndex[action.refId] = action;
		},
	},

	state: {
		actionContext: null,
		actionContextHook: null,
		sequenceContext: null,
		taskDbIndex: {},
		taskRefIndex: {},
		actionRefIndex: {},
		list: [],
		logs: [],
		focusStack: [],
		focused: null,
		addMenu: {
			open: false,
		},
		currentLogActions: [],
		singleActionDesigner: {
			isTask: false,
			id: null,
			inlineId: null,
		},
		dynamicSource: null,
	},

	getters: {
		actionContext: (state) => state.actionContext,
		sequenceContext: (state) => state.sequenceContext,

		actions: (state) => state.list.filter((x) => x.type == "action"),

		addMenu: (state) => ({
			open: state.addMenu.open,
		}),
		data: (state) => ({
			list: state.list,
			getFromRefId: (refId) => state.taskRefIndex[refId],
			getActionFromRefId: (refId) => {
				return state.actionRefIndex[refId];
			},
			getFromId: (id) => state.taskDbIndex[id],
		}),
		singleActionDesigner: (state) => {
			let data = null;

			if (state.singleActionDesigner.id !== null) {
				data = state.taskDbIndex[state.singleActionDesigner.id];
			}

			return {
				isOpen: state.singleActionDesigner.isOpen,
				isExisting:
					state.singleActionDesigner.id != null ||
					state.singleActionDesigner.inlineId != null,
				isTask: state.singleActionDesigner.isTask,
				id: state.singleActionDesigner.id,
				inlineId: state.singleActionDesigner.inlineId,
				data,
			};
		},
		dynamicSource: (state) => ({
			open: state.dynamicSource != null,
			data: state.dynamicSource,
		}),
	},
};
