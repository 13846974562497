/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_R4LKxJMh4",
    "aws_user_pools_web_client_id": "6oc93fgj5pahnaphbss5obe53t",
    "oauth": {
        "domain": "nonoughtweb-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://nonought.io/",
        "redirectSignOut": "https://nonought.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
