<template>
  <div class="home page">
    <img alt="Logo" src="/images/logo-120.png">
    <h1>NoNought</h1>

    <blockquote>Never have a 0% day!</blockquote>

    <SignIn />
  </div>
</template>

<script>
import SignIn from "../components/SignIn";

export default {
  name: 'Home',
  components: {
    SignIn
  }
}
</script>
