<template>
	<div class="text-input">
		<label :for="id">{{label}}</label>
		<input
			ref="txt"
			:id="id"
			:type="type"
			:name="label"
			:value="value"
			@input="input"
			placeholder="..."
			autocomplete="false"
		/>
	</div>
</template>

<script>
export default {
	props: {
		id: {},
		label: {},
		type: String,
		value: {
			default: "",
		},
		fnModValue: {
			type: Function,
			default: v => v
		}
	},
	methods: {
		input() {
			let value = this.$refs.txt.value;
			if (this.type == "number") {
				value = parseInt(value);
				if (isNaN(value)) value = 0;
			}

			this.$emit("input", this.fnModValue(value));
		},
	},
};
</script>
