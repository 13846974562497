import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Auth } from "aws-amplify";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () => import("../views/Privacy.vue"),
	},
	{
		path: "/terms",
		name: "Terms",
		component: () => import("../views/Terms.vue"),
	},
	{
		path: "/user",
		name: "User",
		component: () => import("../views/User.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/board",
		name: "Board",
		component: () =>import("../views/Board.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: () => import("../views/entities/List.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/actions/:id",
		name: "Action",
		component: () => import("../views/entities/Action.vue"),
		meta: {
			requiresAuth: true
		},
		props: route => ({
			id: route.params.id == 'create' ? null : route.params.id
		})
	},
	{
		path: "/logs/:refId",
		name: "Log",
		component: () => import("../views/Log.vue"),
		meta: {
			requiresAuth: true
		},
		props: route => ({
			refId: route.params.refId
		})
	},
	{
		path: "/plans",
		name: "Plans",
		component: () => import("../views/Plans.vue"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/plans/:id",
		name: "Plan",
		component: () => import("../views/Plan.vue"),
		meta: {
			requiresAuth: true
		},
		props: route => ({
			id: route.params.id == 'create' ? null : route.params.id
		})
	},
	{
		path: "/sequences/:id",
		name: "Sequence",
		component: () => import("../views/entities/Sequence.vue"),
		meta: {
			requiresAuth: true
		},
		props: route => ({
			id: route.params.id == 'create' ? null : route.params.id
		})
	},
	{
		path: "/connected-apps",
		name: "ConnectedApps",
		component: () => import("../views/ConnectedApps.vue"),
		meta: {
			requiresAuth: true
		},
	},
	{
		path: "/subscription",
		name: "Subscription",
		component: () => import("../views/Subscription.vue"),
		meta: {
			requiresAuth: true
		},
	},
	{
		path: "/sign-up",
		name: "SignUp",
		component: () => import("../views/SignUp.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const isAuthenticated = await Auth.currentUserInfo();

	store.dispatch("clearDialog");

	if (requiresAuth && !isAuthenticated) {
		next("/");
	} else {
		next();
	}
});

export default router;
