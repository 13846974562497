export const getSingleActionCompletion = action => {
	if (action.completed === 0)
		return {
			value: 0
		};
		
	return {
		value: 1
	}
}

export const getMultiActionCompletion = actions => {
	const all = (actions||[]).map(action => getSingleActionCompletion(action));
	
	const count = all.length;
	const total = all.reduce((p,c) => (p + c.value), 0.0);
	
	return {
		value: total / count
	};
}