<template>
	<nav>
		<ul class="flex justify-end">
			<li class="nav-title">
				<router-link to="/board">NoNought</router-link>
			</li>
			<li v-if="!user">
				<router-link to="/">
					<a>Sign In</a>
				</router-link>
			</li>
			<li v-if="!user">
				<router-link to="/sign-up">
					<a>Sign Up</a>
				</router-link>
			</li>
			<li v-if="!user">
				<router-link to="/privacy">Privacy</router-link>
			</li>
			<li v-if="!user">
				<router-link to="/terms">Terms</router-link>
			</li>
			<li v-if="user">
				<router-link to="/board"><i class="fal fa-clipboard-check"></i></router-link>
			</li>
			<li v-if="user">
				<router-link to="/user"><i class="fal fa-user"></i></router-link>
			</li>
			<li v-if="user">
				<a href="#" @click.prevent="signOut">
					<i class="fal fa-sign-out"></i>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters({
			user: "auth/user",
		}),
	},
	methods: {
		...mapActions({
			signOutAction: "auth/signOut",
		}),
		async signOut() {
			await this.signOutAction();
			this.$router.push("/");
		},
	},
};
</script>
