import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import Amplify, { Auth } from "aws-amplify";
import "@aws-amplify/ui-vue";
import aws from "./aws-exports";
import './registerServiceWorker'

Amplify.configure(aws);

Vue.config.productionTip = false;

axios.interceptors.response.use(
	response => response,
	async (error) => {
        const originalRequest = error.config;
        // console.log(error);
		if (!originalRequest._retry) {
			originalRequest._retry = true;
			const userAuth = await Auth.currentAuthenticatedUser();
			const token = userAuth.signInUserSession.idToken.jwtToken;

			if (token) {
				axios.defaults.headers.common["Authorization"] = token;
				axios.defaults.headers.common["Content-Type"] =
					"application/json";
				Promise.resolve();
			} else Promise.reject(error);
		} else Promise.reject(error);
	}
);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
