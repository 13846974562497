// import {} from "@/helpers/functions"
import {
	list,
	create as apiCreate,
	update as apiUpdate,
	deleteLog as apiDelete,
} from "@/helpers/api/logs";
import { once, throttle } from "../helpers/functions";
import { isnull } from "../helpers/objects";
// import { v4 as uuid } from "uuid";

import { create as localCreate } from "../logic/logs/logs-crud";
import { getMultiActionCompletion } from "../logic/logs/logs-completion";

const save = async (refId) => {
	console.log(`attempting to save logs/${refId}`);
}

const getActionInt = (action, localIndex, taskIndex) => {
	if (action.refId) {
		return {
			refId: action.refId,
			completed: action.completed || 0,
			dynamicData: action.dynamicData || null
		};
	}
	if (action.inlineId) {
		const inline = localIndex[action.inlineId];
		return {
			refId: inline.refId,
			completed: action.completed || 0,
			dynamicData: action.dynamicData || null
		};
	}
	if (action.entityId) {
		const entity = taskIndex[action.entityId];
		return {
			refId: entity.refId,
			completed: action.completed || 0,
			dynamicData: action.dynamicData || null
		}
	}
	return {
		action
	}
};

const listAction = async ({ commit, rootState }) => {
	const res = await list();
	if (!res || !res.data) return res;

	commit("setList", res.data.data.map((log) => {
		const task = rootState.tasks.taskDbIndex[log.taskId];
		return {
			id: log._id,
			refId: log.refId,
			taskId: log.taskId,
			// taskRefId
			type: log.type,
			name: log.name,
			date: log.date,
			// history
			actions: (log.actions || []).map(action => getActionInt(action, task.inlineActions, rootState.tasks.taskDbIndex)),
			completion: log.completion || { value: 0 },

			inlineActions: log.inlineActions || [],
			positive: isnull(log.positive, true),
			progress: 0,
		}
	}));

	return Promise.resolve();
};

const create = async ({ commit, rootGetters, rootState }, { taskRefId, date }) => {
	const task = rootGetters["tasks/data"].getFromRefId(taskRefId);
	const log = localCreate(task, date);
	log.actions = (log.actions || []).map(action => getActionInt(action, task.inlineActions, rootState.tasks.taskDbIndex));
	commit("createLog", log);

	// update api in background
	// todo: create background handling for api calls
	apiCreate({ taskId: task._id, date, refId: log.refId })
		.then(res => {
			if (res.status == 200)
			{
				commit("updateLogId", { refId: log.refId, id: res.data.data.id });
			}
		});
	return Promise.resolve({ data: log });
};

const update = async ({ commit }, log) => {
	commit("updateLogContext", log);
	return await apiUpdate({ id: log.id, data: log });
};

const deleteAction = async ({ commit, state }, refId) => {
	const log = state.index_refId[refId];
	if (log)
	{
		apiDelete({ id: log.id });
		commit("deleteLog", { refId, id: log.id });
	}

	return Promise.resolve();
};

const setLogContext = async ({ commit }, refId) => {
	commit("setCurrentLoading", true);
	// await dispatch("tasks/list", null, { root: true });
	// await dispatch("list");
	commit("setLogContext", refId);
	commit("remapCurrentActions");

	// if (getters.current && getters.current.data)
	// 	await dispatch("tasks/updateCurrentLogActions", getters.current.data, {
	// 		root: true,
	// 	});

	commit("setCurrentLoading", false);
};

const assignDynamicData = async (
	{ commit },
	{ actionIndex, data }
) => {
	// todo: post to logs/:id/dynamic-data
	commit("setDynamicData", { actionIndex, data });
	commit("remapCurrentActions");
	// await dispatch("tasks/updateCurrentLogActions", getters.current.data, {
	// 	root: true,
	// });
};

export default {
	namespaced: true,

	actions: {
		list: listAction,
		listOnce: once(listAction),
		listThrottled: throttle(listAction, 600000, true),

		save,

		create,

		update,

		deleteLog: deleteAction,

		setLogContext,

		assignDynamicData,
	},

	mutations: {
		pushOneToList: (state, payload) => {
			state.list.push(payload);
		},
		setList: (state, payload) => {
			state.list = payload;
			state.index_refId = state.list.reduce((p, c) => {
				p[c.refId] = c;
				return p;
			}, {});
			state.index_id = state.list.reduce((p, c) => {
				p[c.id] = c;
				return p;
			}, {});
		},
		createLog: (state, log) => {
			state.list.push(log);
			state.index_refId[log.refId] = log;
		},
		deleteLog: (state, { refId, id }) => {
			const idx = state.list.findIndex(x => x.refId == refId);			
			if (idx >= 0)
				state.list.splice(idx, 1);
			
			if (id)
				delete state.index_id[id];

			delete state.index_refId[refId];
		},
		updateLogId: (state, { refId, id }) => {
			state.index_refId[refId].id = id;
		},

		setLogContext: (state, refId) => {
			state.logContext = state.index_refId[refId];
		},
		updateLogContext: (state, log) => {
			state.logContext.actions = log.actions;
			state.logContext.completion = getMultiActionCompletion(log.actions);
		},

		setActionDynamicSourcesOpen: (state, open) => {
			state.actionDynamicSources = open;
		},

		setCurrentLoading: (state, loading) => {
			state.currentLoading = loading;
		},

		remapCurrentActions: (state) => {
			if (!state.logContext) state.currentActions = [];
			else
				state.currentActions = state.logContext.actions.map(
					(action) => {
						return {
							id: action.entityId,
							completed: 0,
							elapsed: 0,
							elapsedAcc: 0,
							...action,
						};
					}
				);
		},
	},

	state: {
		logContext: null,
		currentLoading: false,
		currentActions: [],
		autoPlay: false,
		list: [],
		index_id: {},
		index_refId: {},
	},

	getters: {
		autoPlay: (state) => state.autoPlay,
		current: (state, _, rootState) => {
			const loading = state.currentLoading;

			if (!state.logContext)
				return {
					loading,
					data: null,
					focusedTask: null,
					actions: [],
					actionTasks: [],
					playable: false,
					completionPerc: 0,
				};

			const actionTasks = rootState.tasks.currentLogActions;
			const actionTaskCount = actionTasks.length;
			const actionCount = state.currentActions.length;
			const progress = state.logContext.progress;
			let completionPerc = 0;
			let focused = null;
			let focusedTask = null;
			if (progress > 0) {
				completionPerc = (progress / actionTaskCount) * 100;
			}

			const count = Math.min(actionCount, actionTaskCount);
			for (var i = 0; i < count; i++) {
				const d = actionTasks[i];
				state.currentActions[i].ready =
					!d.isDynamic ||
					d.isDynamic === undefined ||
					state.currentActions[i].isDynamicAssigned === true;
			}

			if (progress >= 0 && progress < actionTaskCount) {
				focused = state.currentActions[progress];
				focusedTask = actionTasks[progress];
			}

			return {
				loading,
				data: state.logContext,
				focused,
				focusedTask,
				actions: state.currentActions,
				actionTasks,
				playable: actionTaskCount > progress,
				completionPerc,
			};
		},
		data: (state) => ({
			list: state.list,
			index_id: state.index_id,
			getFromRefId: (refId) => state.index_refId[refId],
			getFromId: (id) => state.index_id[id],
		}),
	},
};
