import { list, create, update } from "../helpers/api/habits";
import { HABIT } from "../data/dialog-keys";
import { once, throttle } from "../helpers/functions";
import { v4 as uuid } from "uuid";

const mapItemToSchedule = (item, type) => ({
	name: item.name,
	defs: item.schedules,
	type,
	refId: item.refId,
});

const listAction = async ({ dispatch, commit }) => {
	const res = await list("/habits");
	if (res && res.data) {
		commit("setList", res.data.data);
		dispatch(
			"registerSchedules",
			res.data.data.filter((x) => x.schedules).map(x => mapItemToSchedule(x, "habit")),
			{ root: true }
		);
	}
	return Promise.resolve();
};

const openDesigner = async ({ commit, dispatch }, habit) => {
	const callbacks = {
		onSaveFunctions: [],
	};
	callbacks.onSave = (fn) => {
		callbacks.onSaveFunctions.push(fn);
	};
	callbacks.invokeSave = (data) => {
		callbacks.onSaveFunctions.forEach((fn) => {
			fn(data);
		});
	};

	commit("setDesignerContext", { habit, callbacks });
	dispatch("pushDialog", HABIT, { root: true });
	return callbacks;
};

const closeDesigner = async ({ commit, dispatch, rootGetters }) => {
	let limit = 10;
	while (
		rootGetters.dialog.current &&
		rootGetters.dialog.current != HABIT &&
		limit-- > 0
	)
		dispatch("popDialog", null, { root: true });
	if (rootGetters.dialog.current == HABIT)
		dispatch("popDialog", null, { root: true });

	commit("setDesignerContext", { habit: null, callbacks: {} });
};

// action context
const save = ({ state, commit, dispatch }, data) => {
	// commit to local state
	commit("save", data);

	const refId = state.designerData.refId;

	dispatch(
		"updateScheduleRef",
		{ refId, schedule: mapItemToSchedule(data, "habit") },
		{ root: true }
	);

	// add to remote save queue
	dispatch(
		"saveQueueAdd",
		{ refId, type: "habit" },
		{ root: true }
	);

	// call hooks
	state.designerCallbacks.invokeSave(state.designerData);
};

const saveRemote = async ({ dispatch, state }, refId) => {
	const data = state.index[refId];

	let id = data._id || data.id || null;
	if (!id) {
		console.log(
			`attempting to create habits / ${data.name} remotely`,
			data
		);
		await dispatch("createRemote", data);
	} else {
		console.log(
			`attempting to update habits / ${data.name} remotely`,
			data
		);
		await dispatch("updateRemote", { id, data });
	}
};

export default {
	namespaced: true,

	actions: {
		list: listAction,
		listOnce: once(listAction),
		listThrottled: throttle(listAction, 600000, true),

		save,
		saveRemote,

		openDesigner,
		closeDesigner,

		createRemote: async ({ commit }, data) => {
			const res = await create(data);
			commit("setRemoteId", { refId: data.refId, id: res.data.data.id });
			return res;
		},

		updateRemote: async (_, { id, data }) => {
			const res = await update({ id, data });
			return res;
		},
	},

	mutations: {
		setList: (state, payload) => {
			state.list = payload;
			state.index = payload.reduce((p, c) => {
				p[c.refId] = c;
				return p;
			}, {});
		},

		setDesignerContext: (state, { habit, callbacks }) => {
			state.designerData = habit;
			state.designerCallbacks = callbacks;
		},

		setRemoteId: (state, { refId, id }) => {
			state.index[refId].id = id;
		},

		save: (state, payload) => {
			let {
				refId,
				name,
				positive,
				alive,
				paused,
				health,
				changeRate,
				changeRateFrequencyIndex,
				schedules,
			} = payload;

			if (!name) throw "Requires name";

			let data = null;
			if (!refId || !state.index[refId]) {
				// create local
				refId = uuid();
				data = { id: null, refId };
				state.index[refId] = data;
				state.list.push(data);
			} else {
				// get from local index
				data = state.index[refId];
			}

			if (!data) {
				// handle data missing
				return;
			}

			if (name != null) data.name = name;
			if (positive != null) data.positive = positive;
			if (alive != null) data.alive = alive;
			if (paused != null) data.paused = paused;
			if (health != null) data.health = health;
			if (changeRate != null) data.changeRate = changeRate;
			if (changeRateFrequencyIndex != null) data.changeRateFrequencyIndex = changeRateFrequencyIndex;
			if (schedules != null) data.schedules = schedules;

			state.designerData = data;
		},
	},

	state: {
		list: [],
		index: {},

		designerData: null,
		designerCallbacks: {},
	},

	getters: {
		data: (state) => ({
			list: state.list,
			get: (refId) => state.index[refId],
		}),
		designer: (state) => ({
			data: state.designerData,
			callbacks: state.designerCallbacks,
		}),
	},
};
