import axios from "axios";
import { apiRoot } from "../config";
import { once } from "../helpers/functions";

const getOptions = async ({ commit }) => {
    var res = await axios.get(`${apiRoot}/subscription/options`);
    if (res.status != 200)
        return;

    commit("setOptions", res.data.data);
}

export default {
    namespaced: true,

    actions: {
        getOptions: once(getOptions)
    },

    mutations: {
        setOptions(state, { product, prices }) {
            state.live = product.live;
            state.options = prices;
        }
    },

    state: {
        live: true,
        options: []
    },

    getters: {
        live: state => state.live,
        options: state => state.options
    }

}