<template>
	<div id="app">

		<div class="notification-panel">
			<div :class="['message', message.type, message.clss]" v-for="(message,i) in messages.all" :key="i" v-html="message.text"></div>
		</div>

		<Navigation />

		<transition name="fade">
			<router-view />
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navigation from "./components/Navigation";

export default {
	components: {
		Navigation
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
			messages: "messages"
		})
	},
	async mounted() {
		document.title = "NoNought";
		await this.authAction();
		await this.getUserOnce();
	},
	watch: {
		"dialog.active"(active) {
			const html = document.getElementsByTagName("html")[0];
			// const body = document.getElementsByTagName("body")[0];
			
			if (active) {
				html.classList.add("noscroll");
			}
			else
			{
				html.classList.remove("noscroll");
			}
		}
	},
	methods: {
		...mapActions({
			authAction: "auth/authAction",
			getUserOnce: "user/getOnce"
		}),
	},
};
</script>

<style lang="scss">
@import "../node_modules/animate.css/animate.css";
@import "./styles/helpers.scss";
@import "./styles/variables.scss";
@import "./styles/mixins.scss";
@import "./styles/animation.scss";
@import "./styles/elements.scss";
@import "./styles/button.scss";
@import "./styles/calendar.scss";

.btn-menu 
{
	position: absolute;
	display: none;
	flex-flow: column nowrap;
	right: 0;
	bottom: 38px;
	padding: 5px;
	background: #fff;
	box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.1);
	min-width: 200px;

	&.active {
		display: flex;
	}

	a {
		font-size: 1rem;
		font-weight: 500;
		padding: 3px 7px;
		margin: 5px;
		display: block;
		text-align: left;

		i {
			margin-right: 10px;
		}
	}
}

h1,
h3 {
	margin: 25px 0;
}
h4 {
	margin: 15px 0;
}
h3 + h4 {
	margin-top: -20px;
	color: #888;
}

a {
	text-decoration: none;
}

.flex-row {
	display: flex;
	flex-flow: row nowrap;
	justify-items: space-between;
	max-width: 100%;
}

.unit-input {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.page {
	padding-top: 70px;
	padding-bottom: 150px;
	min-height: 100.01vh;
	overflow-x: hidden;
}

@import "./styles/board.scss";
@import "./styles/pinned.scss";
@import "./styles/menu.scss";
@import "./styles/notifications.scss";
@import "./styles/chips.scss";

.focus-card {
	display: none;
	background: #fff;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 80px;
	right: 20px;
	left: 20px;
	z-index: 100;
	height: 300px;

	.units {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 20px;

		span {
			margin: 0 15px;
			font-size: 25px;
		}
	}

	.actions {
		display: flex;
		flex-flow: row nowrap;
	}

	&.active {
		display: flex;
	}

	h3 {
		font-size: 30px;
	}

	button, a {
		height: 65px;
		width: 65px;
		box-shadow: none;
		margin: 5px;
		font-size: 40px;
	}
}

@import "./styles/action-bar.scss";

.plan-schedule .days {
	// display: flex;
	// flex-flow: row wrap;
	// justify-content: flex-start;
	// margin: 0 auto;

	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 15px;
}

.plan-schedule__task {
	font-size: 8px;
	padding: 5px;
	background: #448ada;
	color: #fff;
	margin-bottom: 5px;
}

.progress {
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	height: 32px;
	margin: 15px;
	padding: 5px;

	.bar
	{
		height: 20px;
		background: $col-check;
		border-radius: 2px;
	}
}

.seg-progress {
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	height: 42px;
	display: grid;
	padding: 5px;
	margin: 15px;
	grid-gap: 5px;

	.bar {
		height: 30px;
		background: #888;
		border-radius: 3px;

		&.active {
			background: #448ada;
		}
	}
}

.plan-schedule__day {
	margin: 5px;

	&.first .container {
		border: 2px solid $col-check;

		.plan-schedule__task {
			background: $col-check;
		}
	}

	.title {
		margin-bottom: 5px;
	}

	.container {
		border: 1px solid #333;
		border-radius: 3px;
		padding: 5px 5px 0;
		min-height: 80px;
	}
}

.plan-schedule__button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	border: 1px solid #cdcdcd;
	color: #cdcdcd;
	margin: 5px;
	border-radius: 3px;
}

.modal {
	position: fixed;
	z-index: 95;
	right: 10px;
	left: 200px;
	text-align: center;
	background: #fff;
	padding: 15px;
	border-radius: 3px;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
	top: 50%;
	transform: translateY(-50%);
}

@import "./styles/dialog.scss";
@import "./styles/inputs.scss";

</style>
