import Vue from "vue";
import Vuex from "vuex";

import dayjs from "dayjs";

Vue.use(Vuex);

import { auth } from "./auth";
import user from "./user";
import tasks from "./tasks";
import logs from "./logs";
import plans from "./plans";
import tags from "./tags";
import habits from "./habits";
import subscription from "./subscription";

import { ACTION, SEQUENCE, LOG_DYNAMIC_SOURCE } from "../data/dialog-keys";
import { mod } from "../helpers/math";

const saveQueueAdd = async ({ dispatch, commit }, { type, refId }) => {
	commit("saveQueueAdd", {
		refId,
		fn: (async () => {
			try {
				switch (type) {
					case "log":
						await dispatch("logs/save", refId);
						break;
					case "action":
						await dispatch("tasks/saveAction", refId);
						break;
					case "sequence":
						await dispatch("tasks/saveSequence", refId);
						break;
					case "plan":
						await dispatch("plans/save", refId);
						break;
					case "habit":
						await dispatch("habits/saveRemote", refId);
						break;
					case "tag":
						await dispatch("tags/saveRemote", refId);
						break;
					default:
						throw "unrecognised resource type " + type
				}
			} catch (err) {
				commit("saveQueueFailureAdd", { type, refId });
				commit("saveQueueReset", refId);
				throw err;
			}
		})(),
	});
};

const saveQueueFailureRetry = async ({ state, dispatch, commit }) => {
	let attempts = [];
	state.saveQueueFailures.forEach((resource) => {
		attempts.push(dispatch("save", resource));
	});
	commit("saveQueueFailuresReset");
	await Promise.all(attempts);
};

const dialogAfterEach = (_, from, direction, { dispatch }) => {
	if (direction <= 0) {
		switch (from) {
			case SEQUENCE:
				dispatch("tasks/clearSequenceContext");
				return;

			case ACTION:
				dispatch("tasks/clearActionContext");
				dispatch("tasks/closeSingleActionDesigner");
				return;

			case LOG_DYNAMIC_SOURCE:
				dispatch("tasks/clearActionContext");
				return;
		}
	}
};

const openCalendar = ({ commit, dispatch }, params) => {
	const callbacks = {
		onSelectFunctions: [],
	};
	callbacks.onSelect = (fn) => {
		callbacks.onSelectFunctions.push(fn);
	};
	callbacks.invokeSelect = (date) => {
		callbacks.onSelectFunctions.forEach((fn) => {
			fn(date);
		});
	};

	commit("setCalendarContext", { callbacks, params: params || {} });
	dispatch("pushDialog", "calendar");

	return callbacks;
};

const openSchedule = ({ commit, dispatch }, params) => {
	const callbacks = {
		onSaveFunctions: [],
	};
	callbacks.onSave = (fn) => {
		callbacks.onSaveFunctions.push(fn);
	};
	callbacks.invokeSave = (data) => {
		callbacks.onSaveFunctions.forEach((fn) => {
			fn(data);
		});
	};

	commit("setScheduleContext", { callbacks, params: params || {} });
	dispatch("pushDialog", "schedule");

	return callbacks;
};

const registerSchedules = ({ commit }, data) => {
	commit("registerSchedules", data);
};

const updateScheduleRef = ({ commit }, { refId, schedule }) => {
	commit("updateScheduleRef", { refId, schedule });
};

export default new Vuex.Store({
	actions: {
		async loadAll({ dispatch }) {
			await dispatch("tasks/listOnce");
			return Promise.all([
				dispatch("tags/listOnce"),
				dispatch("logs/listOnce"),
				dispatch("plans/listOnce"),
				dispatch("habits/listOnce"),
			]);
		},

		saveQueueAdd,
		saveQueueFailureRetry,

		setDayOffset({ commit }, dayOffset) {
			commit("setDayOffset", dayOffset);
		},
		incrDayOffset({ commit, state }) {
			commit("setDayOffset", state.dayOffset + 1);
		},
		decrDayOffset({ commit, state }) {
			commit("setDayOffset", state.dayOffset - 1);
		},

		broadcastMessage: ({ commit }, text) => {
			commit("createMessage", { text, type: "info" });
		},
		broadcastSuccess: ({ commit }, text) => {
			commit("createMessage", { text, type: "success" });
		},
		broadcastError: ({ commit }, text) => {
			commit("createMessage", { text, type: "error" });
		},

		pushDialog: ({ commit, getters, dispatch }, id) => {
			const from = getters.dialog.current;
			commit("pushDialog", id);
			dialogAfterEach(id, from, 1, { commit, getters, dispatch });
		},
		switchDialog: ({ commit, getters, dispatch }, id) => {
			const from = getters.dialog.current;
			commit("switchDialog", id);
			dialogAfterEach(id, from, 0, { commit, getters, dispatch });
		},
		setDialog: ({ commit, getters, dispatch }, id) => {
			const from = getters.dialog.current;
			commit("setDialog", id);
			dialogAfterEach(id, from, -1, { commit, getters, dispatch });
		},
		popDialog: ({ commit, getters, dispatch }) => {
			const from = getters.dialog.current;
			commit("popDialog");
			const to = getters.dialog.current;
			dialogAfterEach(to, from, -1, { commit, getters, dispatch });
		},
		clearDialog: ({ commit, getters, dispatch }) => {
			const from = getters.dialog.current;
			commit("clearDialog");
			dialogAfterEach(null, from, -1, { commit, getters, dispatch });
		},

		openCalendar,
		openSchedule,
		registerSchedules,
		updateScheduleRef,
	},

	mutations: {
		saveQueueAdd: (state, { refId, fn }) => {
			if (!state.saveQueue[refId]) {
				state.saveQueue[refId] = {
					op: Promise.resolve(),
					last: null,
				};
			}

			state.saveQueue[refId].last = Date.now();
			state.saveQueue[refId].op = state.saveQueue[refId].op.then(
				() => fn
			);
		},
		saveQueueReset: (state, refId) => {
			state.saveQueue[refId].op = Promise.resolve();
		},

		saveQueueFailureAdd: (state, { type, refId }) => {
			state.saveQueueFailures.push({ type, refId });
		},
		saveQueueFailureReset: (state) => {
			state.saveQueueFailures = [];
		},

		setDayOffset: (state, dayOffset) => {
			state.dayOffset = dayOffset;
		},

		createMessage: (state, { text, type }) => {
			const message = { text, type, clss: "enter" };
			state.messages.push(message);
			setTimeout(() => (message.clss = null), 300);
			setTimeout(() => (message.clss = "exit"), 2700);
			setTimeout(() => state.messages.splice(0, 1), 3000);
		},
		removeFirstMessage: (state) => {
			state.messages.splice(0, 1);
		},
		pushDialog: (state, id) => {
			state.dialogStack.push(id);
			state.dialogCurrent = id;
		},
		switchDialog: (state, id) => {
			if (state.dialogStack.length > 0)
				state.dialogStack[state.dialogStack.length] = id;
			else state.dialogStack.push(id);

			state.dialogCurrent = id;
		},
		setDialog: (state, id) => {
			state.dialogStack = [id];
			state.dialogCurrent = id;
		},
		popDialog: (state) => {
			if (state.dialogStack.length > 0) {
				state.dialogStack.pop();

				if (state.dialogStack.length > 0) {
					state.dialogCurrent =
						state.dialogStack[state.dialogStack.length - 1];
				} else {
					state.dialogCurrent = null;
				}
			}
		},
		clearDialog: (state) => {
			state.dialogStack = [];
			state.dialogCurrent = null;
		},

		setCalendarContext: (state, { callbacks, params }) => {
			state.calendarCallbacks = callbacks;
			state.calendarParams = params;
		},

		setScheduleContext: (state, { callbacks, params }) => {
			state.scheduleCallbacks = callbacks;
			state.scheduleParams = params;
		},

		registerSchedules: (state, data) => {
			data.forEach((d) => {
				d.defs.forEach((def) => {
					const from = dayjs.unix(def.from);
					def.fromDate = from.date();
					def.fromWeekday = mod(from.day() - 1, 7);
					def.fromMonth = from.month() + 1;
					def.fromYear = from.year();
				});

				if (state.scheduleIndex[d.refId]) {
					state.scheduleIndex[d.refId] = d;
				} else {
					state.scheduleIndex[d.refId] = d;
					state.scheduleList.push(state.scheduleIndex[d.refId]);
				}
			});
		},

		updateScheduleRef: (state, { refId, schedule }) => {
			const idx = state.scheduleList.findIndex((x) => x.refId == refId);
			if (schedule.defs.length == 0) {
				if (idx >= 0) state.scheduleList.splice(idx, 1);

				delete state.scheduleIndex[refId];
			} else {
				if (idx < 0) state.scheduleList.push(schedule);
				else state.scheduleList[idx] = schedule;

				state.scheduleIndex[refId] = schedule;
			}
		},
	},

	state: {
		dayOffset: 0,
		messages: [],
		dialogStack: [],
		dialogCurrent: null,

		saveQueue: {},
		saveQueueFailures: [],

		calendarCallbacks: [],
		calendarParams: {},

		scheduleCallbacks: [],
		scheduleParams: {},

		scheduleIndex: {},
		scheduleList: [],
	},

	getters: {
		dayOffset: (state) => state.dayOffset,

		messages: (state) => ({
			all: state.messages,
		}),

		dialog: (state) => {
			return {
				active: state.dialogStack.length > 0,
				current: state.dialogCurrent,
				stack: state.dialogStack,
				includes: state.dialogStack.includes,
			};
		},

		unitData: () => ({
			time: {
				s: {
					name: "Seconds",
					factor: 1000,
					prefix: "",
					suffix: "s",
				},
				m: {
					name: "Minutes",
					factor: 60000,
					prefix: "",
					suffix: "m",
				},
				h: {
					name: "Hours",
					factor: 3600000,
					prefix: "",
					suffix: "hrs",
				},
			},
			weight: {
				g: {
					name: "Grams",
					factor: 1,
					prefix: "",
					suffix: "g",
				},
				kg: {
					name: "Kilo-grams",
					factor: 1000,
					prefix: "",
					suffix: "kg",
				},
				lb: {
					name: "Pounds",
					factor: 0.00220462,
					prefix: "",
					suffix: "lbs",
				},
			},
			liquid: {
				ml: {
					name: "Millilitres",
					factor: 1,
					prefix: "",
					suffix: "ml",
				},
				l: {
					name: "Litres",
					factor: 1000,
					prefix: "",
					suffix: "l",
				},
			},
			quantity: {
				n: {
					name: "Repeats",
					factor: 1,
					prefix: "x ",
					suffix: "",
				},
			},
		}),

		calendar: (state) => ({
			callbacks: state.calendarCallbacks,
			params: state.calendarParams,
		}),

		schedule: (state) => ({
			callbacks: state.scheduleCallbacks,
			params: state.scheduleParams,
		}),

		schedules: (state) => ({
			list: state.scheduleList,
		}),
	},

	modules: {
		auth,
		user,
		tasks,
		logs,
		plans,
		tags,
		habits,
		subscription,
	},
});
