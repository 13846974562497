import { v4 as uuid } from "uuid";

export const getActionLogData = (action) => {
	return {
        refId: action.refId,
        entityId: action.entityId,
        inlineId: action.inlineId,
        completed: action.completed || 0
	};
};

export const createSequenceActions = (actions, loops) => {
    let output = [];
	if (!loops) output = actions.map(getActionLogData);
	else {
		let i = 0;
		let allActions = actions.map(getActionLogData);
		loops.forEach((loop) => {
			// add all actions before the current loop
			while (i < loop.from)
				output.push(getActionLogData(actions[i++]));

			// get actions within loop and append * repeat
			let s = allActions.slice(loop.from, loop.to + 1);
			for (var j = 0; j < loop.repeat; j++)
				output = [...output, ...s];

			// update pointer
			i = loop.to + 1;
		});

		while (i < actions.length)
			output.push(getActionLogData(actions[i++]));
    }
    return output;
};

export const create = (task, date) => {
    // console.log(task);
	let log = {
		id: null,
		refId: uuid(),
		taskId: task.id || task._id || null,
		taskRefId: task.refId,
		type: task.type,
		name: task.name,
		positive: task.positive,
		date: date || Date.now(),
        history: [],
        completion: { value: 0 }
	};

	if (log.positive == null)
		log.positive = true;

	switch (task.type) {
		case "action":
			log.actions = [getActionLogData(task)];
			break;

		case "sequence":
            log.actions = createSequenceActions(task.actions, task.loops);
			break;
	}

	return log;
};
