export const once = (fn) => {
	let p = null;
	return function() {
		if (p) return p;

		const ctx = this;
		const args = arguments;

		p = new Promise(resolve => {
			const res = fn.apply(ctx, args);
			resolve(res);
		});
		return p;
	};
};

export const throttle = (fn, milliseconds) => {
	let lastRan;
	let p = null;
	return function() {
		const ctx = this;
		const args = arguments;

		if (p && Date.now() - lastRan < milliseconds) return p;

		p = new Promise(resolve => {
			const res = fn.apply(ctx, args);
			lastRan = Date.now();
			resolve(res);
		});
	};
};

export const debounce = (fn, milliseconds, immediate) => {
	let debouncing = false;
	let last = null;
	return function() {
		clearTimeout(debouncing);
		const ctx = this;
		const args = arguments;

		if (immediate && (last === null || Date.now() - last > milliseconds)) {
			last = Date.now();
			fn.apply(ctx, args);
		} else {
			debouncing = setTimeout(() => fn.apply(ctx, args), milliseconds);
		}
	};
};
