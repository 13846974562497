// logs
export const LOG = "log";
export const LOGS_ADD = "logs-add";

export const LOG_FOCUS = "log-focus";
export const LOG_DYNAMIC_SOURCE = "log-dynamic-source";
export const LOG_DYNAMIC_SOURCE_MANUAL = "log-dynamic-source-manual";
export const LOG_DYNAMIC_SOURCE_TRELLO = "log-dynamic-source-trello";
export const LOG_REMOVE = "log-remove";

// tasks
export const TASKS = "tasks";
export const TASKS_FROMCODE = "tasks-fromcode";

export const TASK_ACTIONS = "task-actions";
export const TASK_LOOPS = "task-loops";

export const SEQUENCE = "sequence";
export const SEQUENCE_ACTIONS = "sequence-actions";
export const SEQUENCE_ACTION = "sequence-action";
export const SEQUENCE_LOOP = "sequence-loop";

export const ACTION = "action";
export const ACTION_DYNAMIC_SOURCES = "action-dynamic-sources";
export const ACTION_UNITS = "action-units";

export const ACTION_DYNAMIC_SOURCE_MANAGER = "action-dynamic-source-manager";

// plans
export const PLAN = "plan";
export const PLAN_CREATE = "plan-create";
export const PLAN_SCHEDULE = "plan-schedule";
export const PLAN_CONTEXT = "plan-context";
export const PLAN_DAY = "plan-day";

export const PLANS = "plans";

// habits
export const HABITS = "habits";
export const HABIT = "habit";

// tags
export const TAG = "tag";

// all
export default {
    LOG,
    LOGS_ADD,

    LOG_FOCUS,
    LOG_DYNAMIC_SOURCE,
    LOG_DYNAMIC_SOURCE_MANUAL,
    LOG_DYNAMIC_SOURCE_TRELLO,

    TASKS,
    TASKS_FROMCODE,

    TASK_ACTIONS,
    TASK_LOOPS,

    SEQUENCE,
    SEQUENCE_ACTIONS,
    SEQUENCE_LOOP,

    ACTION,
    ACTION_DYNAMIC_SOURCES,
    ACTION_UNITS,

    ACTION_DYNAMIC_SOURCE_MANAGER,
    
    PLAN,
    PLAN_SCHEDULE,
    PLAN_CONTEXT,
    PLAN_DAY,

    PLANS,

    HABITS,
    HABIT
}