import { list, create, update } from "@/helpers/api/plans";
import { once, throttle } from "../helpers/functions";
import dayjs from "dayjs";
import { MILLIS_TO_DAYS } from "../helpers/time";
import { mod } from "../helpers/math";

const save = async ({ state }, refId) => {
	console.log(`attempting to save plans/${refId}`);

	const plan = state.refIndex[refId];
	let id = plan._id || plan.id || null;

	if (!id) {
		console.log(`attempting to create plans/${refId}`);
	}
	else {
		console.log(`attempting to update plans/${refId}`);
	}
}

const fnList = async ({ commit }) => {
	const res = await list();
	if (res && res.data)
	{
		commit("setList", res.data.data);
	}
	return Promise.resolve();
};

const fnCreate = async ({ dispatch }, payload) => {
	await create(payload);
	await dispatch("list");
};

const fnUpdate = async ({ dispatch }, { id, data }) => {
	await update({ id, data });
	await dispatch("list");
};

export default {
	namespaced: true,

	actions: {
		list: once(fnList),
		listOnce: once(fnList),
		listThrottled: throttle(fnList, 600000, true),
		create: fnCreate,
		update: fnUpdate,

		save,
	},
	
	mutations: {
        setCurrent: (state, payload) => state.current = payload,
		setList: (state, payload) => {
			state.list = payload;
			state.index = payload.reduce((p, c) => {
				p[c.refId] = c;
				return p;
			}, {});
			state.history = payload.reduce((p, c) => [...p, ...(c.history || []).map(x => ({ ...x, name: c.name }))], []);
		},
	},

	state: {
        current: null,
		list: [],
		history: [],
		index: {},
		listByFrom: []
	},

	getters: {
		data: (state) => ({
			list: state.list,
			get: (refId) => state.index[refId],
			getFromDay: (unixTime) => {
				return state.history.filter(x => x.from <= unixTime && (x.to == null || x.to > unixTime));
			},
			getFromMonth: (year, month) => {
				const from = new Date(year, month - 1, 1, 0, 0, 0).getTime();
				const to = new Date(year, month, 1, 0, 0, 0).getTime();
				const monthStart = dayjs(`${year}-${month}-01`);
				const monthDays = monthStart.daysInMonth();

				const plans = state.history.filter(x => x.from < to && (x.to == null || x.to >= from));
				let data = {};

				plans.forEach(plan => {
					const offset = (plan.from - from) * MILLIS_TO_DAYS + 1;

					for (var i = 0; i < monthDays; i++)
					{
						const daysSincePlanStart = (i + 1) - offset;

						if (daysSincePlanStart < 0)
							continue;

						const unixTime = new Date(year, month - 1, i + 1, 0, 0, 0).getTime();

						if (to != null && to < unixTime)
							break;
					
						const planIndex = mod(daysSincePlanStart, plan.days.length);

						if (plan.days[planIndex] && plan.days[planIndex].length > 0)
						{
							if (!data[i])
								data[i] = [];

							data[i] = [...data[i], ...plan.days[planIndex]];
						}
					}
				});

				return data;
			}
		}),
		current: (state) => ({
            open: state.current != null,
            data: state.current
        }),
	},
};
